import styled from 'styled-components'

const HeadingBig = styled.h1`
  font-size: 6rem;
  margin: 0;
  /* position: relative; */
  line-height: 1;
  font-family: 'Roboto', sans-serif;
`

export default HeadingBig