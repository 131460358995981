const theme = {
  light: {
    textColor: '#111',
    textSec: '#333',
    textWhite: '#fff',
    background: '#fbfbfb',
    color1: '#f04b3c',
    color2: '#30336b',
  }
}

export default theme

